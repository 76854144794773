import { Header, toImageProps } from '@front/shared/ds';
import { addJurisdictionSupport } from '@front/shared/utils';
import { Header as THeader, Locale, Jurisdiction } from '@shared/master-types';

import { useActions } from '../../../features/actions/useActions.hook';

export type THeaderConnectedConnectedProps = Extract<
  Required<THeader>['header'][number],
  { blockType: 'header-simple' }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
};

const HeaderConnected = (
  props: THeaderConnectedConnectedProps,
): JSX.Element => {
  const {
    logo,
    locales = [],
    menuItems,
    menuActions,
    jurisdictionList,
    jurisdictionItem,
  } = props;

  const actionMenuItems = addJurisdictionSupport({
    actionList: useActions(menuItems),
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
  });
  const actionButtons = addJurisdictionSupport({
    actionList: useActions(menuActions),
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
  });

  return (
    <Header
      logo={toImageProps(logo)}
      menuItems={actionMenuItems}
      actions={actionButtons}
      jurisdictionList={jurisdictionList}
      jurisdictionItem={jurisdictionItem}
      locales={locales}
    />
  );
};

export default HeaderConnected;
